<template>
  <div class="inside-page-content">
    <div class="paper light-grey-bg">
      <div class="flex-box">
        <vertical-menu
          class="left-menu-box"
          title="数字服务"
          :menuData="menuData"
          @handleMenuChange="handleMenuChange"
          :defaultCode="menu_id"
        ></vertical-menu>
        <div class="right-list-box" v-if="menu_id=='default'">
          <cell-ruzhuqiye
            v-for="item in dataList"
            :key="item.id"
            @goDetail="goDetail"
            @goApply="goApply"
            :info="item"
          ></cell-ruzhuqiye>
          <div class="footer">
            <div class="left">共 {{total}} 条</div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :current-page.sync="queryParams.pageNum"
              :page-size="queryParams.pageSize"
              @current-change="getList"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import articleList from '@/components/articleList.vue'
import verticalMenu from '@/components/verticalMenu.vue'
import cellRuzhuqiye from './components/cell-ruzhuqiye.vue'

export default {
  name: 'Home',
  components: {
    articleList,
    verticalMenu,
    cellRuzhuqiye
  },
  data() {
    return {
      menuData: [{
        'name': '环境试验',
        'code': 'default'
      }],
      menu_id: 'default',
      dataList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 6,
      },
      // 总条数
      total: 0,
    }
  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const menu_id = this.$route.query?.menu_id
    if (menu_id&&menu_id!='undefined') {
      this.menu_id = menu_id
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$api.digitalServices.environmentList(this.queryParams).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList = data.rows;
          this.total = data.total;
        }
      })
    },
    handleMenuChange(item) {
      console.log('handleMenuChange = ', item)
      this.menu_id = item.code
    },
    goDetail(item) {
      this.$router.push({ path: '/digitalServices/page-hjsy/articledetail', query: { id: item.id, 'detailType': 'ds-environment' } })
    },
    goApply(item) {
      this.$router.push({ path: '/digitalServices/page-hjsy/applyservice', query: { id: item.id, 'name': item.title, 'detailType': 'ds-environment' } })
    },
    goback() {
      this.$router.back()
    },
    onSubmit() { },
  }
}
</script>
<style scoped lang="less">
.policy-article-wrapper {
  display: flex;
  flex-direction: row;

  .left {
  }

  .right {
  }
}
</style>
